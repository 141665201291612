import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { DonationPageLayout } from '../components/layouts'

class PagesDonationTemplate extends React.Component {

    render() {
        const { location } = this.props;
        const pageDonation = this.props.data.contentfulPageDonation
        const site = this.props.data.site.siteMetadata
        let sitemap = get(this.props, 'data.allContentfulWidgetSitemap.edges', [])
        sitemap = sitemap.map(link => link && link.node)

        return (
            <DonationPageLayout location={location} pageDonation={pageDonation} site={site} sitemap={sitemap} />
        )
    }
}

export default PagesDonationTemplate

export const pageQuery = graphql`
query PagesDonationBySlug($slug: String!, $locale: String!) {
    site {
        siteMetadata {
            title
            description
            siteUrl
            twitterHandle
        }
    }
    contentfulPageDonation(slug: { eq: $slug }, node_locale: { eq: $locale }) {
        __typename
        title
        slug
        topRichText {
            childContentfulRichText {
                internal {
                    content
                }
                html
            }
        }
        downRichText {
            childContentfulRichText {
                internal {
                    content
                }
                html
            }
        }
        paperform {
            slug
        }
    }
    allContentfulWidgetSitemap(filter:{title: {ne: "Don't delete - sitemap"}}) {
        totalCount
        edges {
            node {
                contentful_id
                userSection
                title
                slug
                links {
                    __typename
                    ... on ContentfulPageCustom {
                        slug
                        title
                        contentful_id
                        userSection
                    }
                    ... on ContentfulPageDirectoryOfServices {
                        id
                        title
                    }
                    ... on ContentfulPageDonation {
                        slug
                        title
                        contentful_id
                    }
                    ... on ContentfulPageGeneric {
                        slug
                        title
                        userSection
                    }
                    ... on ContentfulPageGroup {
                        slug
                        title
                        userSection
                    }
                    ... on ContentfulPageGuideline {
                        title
                        slug
                    }
                    ... on ContentfulPageWidgets {
                        title
                        slug
                        userSection
                    }
                }
            }
        }
    }
}
`
